
import fetch from '@/utils/fetch';
/* ---
（外部用户APP、网上营业厅）通过用户手机解绑户号
*/
export function hlwYxyh_updateYxyhBdztFromYhsj(yhbh, yhsj, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwYxyh/updateYxyhBdztFromYhsj',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                yhbh : yhbh, //String
				yhsj: yhsj,//String
            }
        }
    })
}
/* ---
（外部用户APP、网上营业厅）通过用户手机默认选择户号
*/
export function hlwYxyh_updateYxyhMrxzbzFromYhsj(yhbh, yhsj, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwYxyh/updateYxyhMrxzbzFromYhsj',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                yhbh : yhbh, //String
                yhsj : yhsj //String
            }
        }
    })
}
